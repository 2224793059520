import { useViewContext, useHandleViewContext } from '../../providers/viewProvider'
import menu from '../../data/menu'


import './styles.scss'
import { useHandleAuthContext } from '../../providers/authProvider'


const Aside = () => {

  const view = useViewContext()
  const setView = useHandleViewContext()
  const setAuth =useHandleAuthContext()

  const handleSection = (e) => {

    setView({ ...view, isMenuOpen: false, sectionId: e.currentTarget.dataset.id, isToolsActive: false })

  }

  const handleLogOut = (e) => {


    localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME)
    setView({ ...view, isMenuOpen: false, isToolsActive: false })
    setAuth(false)

  }

  return (

    <>
      <aside className={`main-aside ${view.isMenuOpen && 'active'}`}>
        {

          menu.map((item,index) => {

            return (

              <div 
                className={`main-aside-section ${view.sectionId===item.id && 'active'}`} 
                key={`list-${item.id}-${index}`} 
                data-id={item.id}
                onClick={handleSection}
                >
                <div className="main-aside-section-icon icon">{item.icon}</div>
                <div className="main-aside-section-text">{item.text}</div>
              </div>

            )

          })

        }
        <div 
          className={`main-aside-section logout`} 
          onClick={handleLogOut}
          >
          <div className="main-aside-section-icon icon">logout</div>
          <div className="main-aside-section-text">Cerrar sesión</div>
        </div>
      </aside>
    </>

  )

}

export default Aside