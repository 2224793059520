import React, { useState, useContext } from 'react'

const viewContext = React.createContext()
const handleViewContext = React.createContext()

export const useViewContext = () => useContext(viewContext)
export const useHandleViewContext = () => useContext(handleViewContext)

export const ViewProvider = ({children}) => {

  const [ view, setView ] = useState({
    
    isMenuOpen: false, 
    sectionId: 'activities',
    isLoading: false,
    isToolsActive: false,
    isPopupActive: false,
    popupAction: 'add',
    popupData: []
  
  })
  const handleView = (data) => setView(data)

  return (

    <viewContext.Provider value={view}>
      <handleViewContext.Provider value={handleView}>
        {children}
      </handleViewContext.Provider>
    </viewContext.Provider>

  )

}