import { useEffect, useState } from 'react'
import './styles.scss'


const Mail = () => {

  const [ msg, setMsg ] = useState({ txt: '', isDisabled: true })

  const getData = () => {

    fetch(`${process.env.REACT_APP_DB_URL}?query=get_mail`, { mode: 'cors' })
      .then((res) => res.json()).then((content) => setMsg({ ...msg, txt: content[0]['msg'] }))
      .catch((error) => setMsg({ ...msg, txt: '' }))

  }

  const handleMsgChange = (e) => {

    const newTxt = e.currentTarget.value
    
    setMsg({txt: newTxt , isDisabled: msg.txt===newTxt })

  }

  const handleSaveButton = (e) => {

    if (!e.currentTarget.classList.contains('disabled')) {

      fetch(`${process.env.REACT_APP_DB_URL}?query=update_mail&msg=${msg.txt}`, { mode: 'cors' })
        .then((res) => res.json()).then((content) => setMsg({ ...msg, isDisabled: true }))
        .catch((error) => alert('No se ha podido modificar el mensaje...'))

    }

  }

  useEffect(() => {

    getData()

  }, [])

  return (

    <div className="mail">
      <div className="mail-content">
        <div className="mail-header">
          Escriba el texto del mensaje que se enviará automáticamente cada mes a todos los usuarios:
        </div>
        <div className="mail-msg">
          <textarea name="msg" placeholder='Mensaje...' defaultValue={msg.txt} onChange={handleMsgChange}></textarea>
          <div className={`popup-button ${msg.isDisabled ? 'disabled' : ''}`} onClick={handleSaveButton}>
            Guardar cambios
          </div>
        </div>
      </div>
    </div>

  )

}

export default Mail