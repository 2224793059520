import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import './styles/normalize.min.css'
import './styles/fonts.css'
import './styles/styles.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<><App /></>)
