import { useEffect, useState } from 'react'
import menu from '../../data/menu'
import { useHandleViewContext, useViewContext } from '../../providers/viewProvider'
import { getSection } from '../../utils/functions'
import './styles.scss'
import { useHandleDataContext } from '../../providers/dataProvider'

const PopUp = () => {

  const view = useViewContext()
  const setView = useHandleViewContext()
  const section = getSection(view, menu)
  const setData = useHandleDataContext()

  const handleCloseButton = () => setView({...view, isPopupActive: false})

  const getData = async () => {

    fetch(`${process.env.REACT_APP_DB_URL}?query=${section.query}`, { mode: 'cors' })
      .then((res) => res.json()).then((content) => setData(content))
      .catch((error) => setData([]))

  }

  const validateForm = (element) => {

    if (element) {

      const isInvalid = element.parentElement.querySelectorAll('input:invalid, select:invalid').length

      isInvalid ? element.classList.add('error') : element.classList.remove('error')

    } else {

      const formData = document.querySelectorAll('.popup-content input, .popup-content select')
      const errorData = document.querySelectorAll('.popup-content input:invalid, .popup-content select:invalid')

      formData.forEach(input => input.classList.remove('error'))
      errorData.forEach(input => input.classList.add('error'))

      return errorData.length ? false : true

    }

  }

  const handleBlur = (e) => validateForm(e.currentTarget)

  const handleSaveButton = () => {

    const formData = document.querySelectorAll('.popup-content input, .popup-content select')
    const action = view.popupAction === 'add' ? 'insert_data' : 'update_data'
    let requestStr = `${process.env.REACT_APP_DB_URL}?query=${action}&table=${section.table}`

    formData.forEach(data => {

      requestStr += `&${data.name}=${data.value}`

    })

    if (validateForm()) {

      fetch(requestStr, { mode: 'cors' })
      .then((res) => res.json()).then((content) => {
        
        setView({...view, isPopupActive: false, isToolsActive: false})
        getData()
      
      })
      .catch((error) => alert('Revise los datos intruducidos, existe algún error y los datos no se han podido guardar en la base de datos.'))

      document.querySelectorAll('.check input:not(.header)').forEach(check => check.checked = false)

    } 

  }

  return (

    <div className={`popup ${view.isPopupActive && ' active'}`}>
      {view.isPopupActive ? 

        <div className="popup-container">
          <div className="popup-header">
            <div className="icon header">{section.icon}</div>
            <div className="title">{section[view.popupAction].title}</div>
            <div className="icon" onClick={handleCloseButton}>close</div>
          </div>
          <div className="popup-content">
            {

              section.fields.map((field, index) => {

                return (

                  <div className={`popup-field ${field.type==='hidden' && 'hide'}`} key={`popup-fields-field-${section.id}-${index}`}>
                    <div className="icon">{field.icon}</div>

                    { field.type === 'select' ?

                      <Select 
                        name={field.id}
                        action={view.popupAction}
                        popupData={view.popupData[0] || []}
                      />

                      :

                      <input 
                        type={field.type} 
                        name={field.id}
                        placeholder={`${field.as}${field.required ? ' *' : ''}`} 
                        required={field.required}
                        onBlur={handleBlur}
                        defaultValue={view.popupAction === 'edit' ? view.popupData[0][field.id] : ''}
                        step={field.type==='number' ? '0.01' : ''}
                      />

                    }

                  </div>

                )

              })

            }
            <div className="popup-button" onClick={handleSaveButton}>Guardar</div>
          </div>
        </div>
      
      :

      <></>
    
      }
    </div>

  )

}

export default PopUp

const Select = ({name, action, popupData}) => {

  const [ options, setOptions ] = useState([])
  const query = {

    actividad: `get_actividades`,
    horario: popupData.actividad ? `search_clase_by_actividad&actividad=${popupData.actividad}` : `get_clases`,
    estado: `get_estados`

  }

  const getOptions = (q) => {

    fetch(`${process.env.REACT_APP_DB_URL}?query=${q}`, { mode: 'cors' })
      .then((res) => res.json()).then((content) => setOptions(content))
      .catch(() => setOptions([]))

  }

  const handleChange = (e) => {

    if (action === 'edit' && e.currentTarget.name === 'actividad') {

      const clasesSelect = document.querySelector('select[name=horario]')

      clasesSelect.innerHTML = ''

      fetch(`${process.env.REACT_APP_DB_URL}?query=search_clase_by_actividad&actividad=${e.currentTarget.value}`, { mode: 'cors' })
      .then((res) => res.json()).then((content) => {

        const opt = document.createElement('option')

          opt.value = ''
          opt.textContent = 'Seleccionar...'
          opt.disabled = true
          opt.selected = true
          
          clasesSelect.appendChild(opt)

        content.forEach(item => {

          const opt = document.createElement('option')

          opt.value = item.id
          opt.textContent = item.nombre
          
          clasesSelect.appendChild(opt)

        })

      })
      .catch(() => setOptions([]))

    }

  }

  useEffect(() => {

    getOptions(query[name])

  }, [])

  return (

    <select name={name} onChange={handleChange}>
      <option value="" selected={action !== 'edit'} disabled>Seleccionar...</option>
      {

        options.map((option, index) => {

          return (

            <option 
              key={`popup-options-${option.id}-${index}`} 
              value={option.id}
              selected={option.id === popupData[name]}
            >
              {option.nombre}
            </option>

          )

        })

      }
    </select>

  )

}
