const menu = [

  {

    id: 'activities',
    table: 'actividades',
    query: 'get_actividades',
    searchQuery: 'search_actividades',
    searchByIdQuery: 'search_actividad_by_id',
    removeQuery: 'remove_actividades',
    confirmTxt: '¿ Está seguro/a de eliminar la/s actividad/es ?\n\n Si existen registros asociados también se eliminarán.',
    icon: 'bolt',
    text: 'Actividades',
    fields: [

      { id: 'id', as: 'Identificador', icon: 'barcode', type: 'text', required: true },
      { id: 'nombre', as: 'Actividad', icon: 'bolt', type: 'text', required: true },
      { id: 'lugar', as: 'Lugar', icon: 'place', type: 'text', required: true },
      { id: 'precio', as: 'Precio', icon: 'euro', type: 'number', required: true },
      { id: 'precio_cu', as: 'Precio universitarios', icon: 'percent', type: 'number', required: true },
      { id: 'cupo_min', as: 'Alumnos mínimos', icon: 'person', type: 'number', required: true },
      { id: 'cupo_max', as: 'Alumnos máximos', icon: 'group', type: 'number', required: true },
      { id: 'descripcion', as: 'Descripción', icon: 'edit_note', type: 'textarea', required: false }
      

    ],
    listFields: [

      { name: 'Id', class: 'id', id: 'id' },
      { name: 'Descripción', class: 'desc', id: 'nombre' },
      { name: 'PVP', class: 'precio', id: 'precio' },
      { name: 'PVP uni.', class: 'precio', id: 'precio_cu' },
      { name: 'Mín. pers.', class: 'cupo', id: 'cupo_min' },
      { name: 'Máx. pers.', class: 'cupo', id: 'cupo_max' }

    ],
    add: { title: 'Nueva actividad' },
    edit: { title: 'Editar actividad' }

  },
  {

    id: 'hours',
    table: 'horarios',
    query: 'get_clases',
    searchQuery: 'search_clases',
    removeQuery: 'remove_clases',
    searchByIdQuery: 'search_clase_by_id',
    confirmTxt: '¿ Está seguro/a de eliminar la/s clase/s ?\n\n Si existen registros asociados también se eliminarán.',
    icon: 'calendar_month',
    text: 'Clases',
    fields: [

      { id: 'actividad', as: 'Actividad', icon: 'bolt', type: 'select', required: true },
      { id: 'id', as: 'Identificador', icon: 'barcode', type: 'text', required: true },
      { id: 'dias', as: 'Días', icon: 'today', type: 'text', required: true },
      { id: 'hora', as: 'Horario', icon: 'schedule', type: 'time', required: true }
      

    ],
    listFields: [

      { name: 'Actividad', class: 'desc', id: 'actividad' },
      { name: 'Días', class: 'dias', id: 'horarios' },
      { name: 'Horario', class: 'hora', id: 'hora' }

    ],
    add: { title: 'Nueva clase' },
    edit: { title: 'Editar clase' }

  },
  {

    id: 'inscriptions',
    table: 'inscripciones',
    query: 'get_inscripciones',
    searchQuery: 'search_inscripciones',
    removeQuery: 'remove_inscripciones',
    searchByIdQuery: 'search_inscripcion_by_id',
    confirmTxt: '¿ Está seguro/a de eliminar la/s inscripción/es ?\n\n Si existen registros asociados también se eliminarán.',
    icon: 'checklist',
    text: 'Inscripciones',
    fields: [

      { id: 'id', as: 'Identificador', icon: 'barcode', type: 'hidden', required: true },
      { id: 'nif', as: 'NIF', icon: 'id_card', type: 'text', required: true },
      { id: 'nombre', as: 'Nombre', icon: 'person', type: 'text', required: true },
      { id: 'apellido_1', as: 'Primer apellido', icon: 'group', type: 'text', required: true },
      { id: 'apellido_2', as: 'Segundo apellido', icon: 'groups', type: 'text', required: false },
      { id: 'email', as: 'Correo electrónico', icon: 'mail', type: 'email', required: true },
      { id: 'telefono', as: 'Teléfono', icon: 'phone', type: 'phone', required: true },
      { id: 'actividad', as: 'Actividad', icon: 'bolt', type: 'select', required: true },
      { id: 'horario', as: 'Clase', icon: 'calendar_month', type: 'select', required: true },
      { id: 'estado', as: 'Estado', icon: 'verified', type: 'select', required: true }
      

    ],
    listFields: [

      { name: 'Estado', class: 'state', id: 'estado' },
      { name: 'Nif', class: 'nif', id: 'nif' },
      { name: 'Nombre y apellidos', class: 'fullName', id: 'nombre' },
      { name: 'Email', class: 'email', id: 'email' },
      { name: 'Actividad', class: 'actividadDesc', id: 'actividad' },
      { name: 'Fecha ins.', class: 'fecha', id: 'inscripcion' }

    ],
    add: { title: 'Nueva actividad' },
    edit: { title: 'Editar inscripción' }

  },
  {

    id: 'mail',
    table: 'correos',
    icon: 'mail',
    text: 'Recordatorio'

  }

]

export default menu