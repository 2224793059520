import { useRef } from 'react'
import './styles.scss'
import { useHandleViewContext, useViewContext } from '../../providers/viewProvider'
import { useRowsContext } from '../../providers/rowsProvider'
import { getSection } from '../../utils/functions'
import menu from '../../data/menu'
import { useHandleDataContext } from '../../providers/dataProvider'

const Tools = () => {

  const tools = useRef()
  const view = useViewContext()
  const setView = useHandleViewContext()
  const rows = useRowsContext()
  const section = getSection(view, menu)
  const setData = useHandleDataContext()

  const getData = async () => {

    fetch(`${process.env.REACT_APP_DB_URL}?query=${section.query}`, { mode: 'cors' })
      .then((res) => res.json()).then((content) => setData(content))
      .catch((error) => setData([]))

  }

  const handleRemoveTool = () => {

    if (window.confirm(section.confirmTxt)) {

      rows.forEach(row => {

        const id = row.dataset.id
  
        fetch(`${process.env.REACT_APP_DB_URL}?query=${section.removeQuery}&id=${id}`, { mode: 'cors' })
          .then((res) => res.json()).then((content) => getData())
          .catch((error) => console.log(error))
  
      })
  
      setView({...view, isToolsActive: false})
  
      document.querySelectorAll('.check input:not(.header):checked').forEach(e => e.checked = false)

    }

  }

  const handleEditTool = () => {

    const id = document.querySelector('.check input:not(.header):checked').parentElement.parentElement.dataset.id

    fetch(`${process.env.REACT_APP_DB_URL}?query=${section.searchByIdQuery}&id=${id}`, { mode: 'cors' })
      .then((res) => res.json()).then((content) => setView({...view, isPopupActive: true, popupAction: 'edit', popupData: content }))
      .catch((error) => console.log(error))

  }

  return (

    <div className={`tools ${view.isToolsActive && 'active'}`} ref={tools}>
      <div className="tools-container">
        <div className={`tool edit ${rows.length>1 && 'disabled'}`} onClick={handleEditTool}>
          <div className="icon">edit</div>
          <div className="text">Editar...</div>
        </div>
        <div className="tool remove" onClick={handleRemoveTool}>
          <div className="icon">delete</div>
          <div className="text">Eliminar ({rows.length})...</div>
        </div>
      </div>
    </div>

  )

}

export default Tools