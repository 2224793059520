import React, { useState, useContext } from 'react'

const dataContext = React.createContext()
const handleDataContext = React.createContext()

export const useDataContext = () => useContext(dataContext)
export const useHandleDataContext = () => useContext(handleDataContext)

export const DataProvider = ({children}) => {

  const [ data, setData ] = useState([])
  const handleData = (d) => setData(d)

  return (

    <dataContext.Provider value={data}>
      <handleDataContext.Provider value={handleData}>
        {children}
      </handleDataContext.Provider>
    </dataContext.Provider>

  )

}