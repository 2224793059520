import { useViewContext, useHandleViewContext } from '../../providers/viewProvider'
import { ReactComponent as Logo } from '../../assets/logo.svg'

import './styles.scss'

const Header = () => {

  const view = useViewContext()
  const setView = useHandleViewContext()

  const handleToggleMenu = () => {
    
    setView({...view, isMenuOpen: !view.isMenuOpen})
  
  }

  return (

    <>
      <header className="top-header">
        <div className="toogle-menu-btn" onClick={handleToggleMenu}>
          <div className="icon">{view.isMenuOpen ? 'menu_open' : 'menu'}</div>
        </div>
        <Logo className="main-logo"/>
        <h1><span>COOLFIT &raquo;</span> Gestión</h1>
      </header>
    </>

  )

}

export default Header