
import { ViewProvider } from './providers/viewProvider'
import { AuthProvider } from './providers/authProvider'
import { RowsProvider } from './providers/rowsProvider'
import { DataProvider } from './providers/dataProvider'

import Login from './components/Login/Login'

const App = () => {

  return (

    <AuthProvider>
      <ViewProvider>
        <RowsProvider>
          <DataProvider>
            <Login/>
          </DataProvider>
        </RowsProvider>
      </ViewProvider>
    </AuthProvider>

  )

}

export default App

//REACT_APP_DB_URL='https://gestion.coolfitgc.com/datos/sql.php'