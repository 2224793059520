import React, { useState, useContext } from 'react'

const authContext = React.createContext()
const handleAuthContext = React.createContext()

export const useAuthContext = () => useContext(authContext)
export const useHandleAuthContext = () => useContext(handleAuthContext)

export const AuthProvider = ({children}) => {

  const [ auth, setAuth ] = useState(false)
  const handleAuth = (data) => setAuth(data)

  return (

    <authContext.Provider value={auth}>
      <handleAuthContext.Provider value={handleAuth}>
        {children}
      </handleAuthContext.Provider>
    </authContext.Provider>

  )

}