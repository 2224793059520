import { useEffect } from 'react'
import { useViewContext, useHandleViewContext } from '../../providers/viewProvider'
import List from '../List/List'
import Mail from '../Mail/Mail'

import menu from '../../data/menu'

import './styles.scss'
import { getSection } from '../../utils/functions'
import { useHandleDataContext } from '../../providers/dataProvider'

const Content = () => {

  const setData = useHandleDataContext()

  const view = useViewContext()
  const setView = useHandleViewContext()
  const section = getSection(view, menu)

  const closeMenu = () => setView({...view, isMenuOpen: false})

  const getData = async () => {

    fetch(`${process.env.REACT_APP_DB_URL}?query=${section.query}`, { mode: 'cors' })
      .then((res) => res.json()).then((content) =>  setData(content))
      .catch((error) => setData([]))

  }

  const handleAddButton = async () => await setView({...view, isPopupActive: true, popupAction: 'add' })

  useEffect(() => {

    getData()

  },[section.query])

  return (

    <div className="content" >
      <div className="content-title">
        <div className="icon">
          {section && section.icon}
        </div>
        <div className="text">
          {section && section.text}
        </div>
        { section.id !== 'mail' && <div className="icon add" title="Añadir..." onClick={handleAddButton}>add</div> }
      </div>
      { section.id === 'mail' ? <Mail/> : <List section={section} /> }
    </div>

  )

}

export default Content