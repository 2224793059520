import { useEffect, useRef, useState } from 'react'
import { useHandleViewContext, useViewContext } from '../../providers/viewProvider'
import { useHandleRowsContext} from '../../providers/rowsProvider'

import './styles.scss'
import { useDataContext, useHandleDataContext } from '../../providers/dataProvider'


const List = ({section}) => {
  
  const [ actividades, setActividades ] = useState([])
  const [ clases, setClases ] = useState([])


  const checkAll = useRef()
  const search = useRef()
  const view = useViewContext()
  const setView = useHandleViewContext()
  const setRows = useHandleRowsContext()
  const data = useDataContext()
  const setData = useHandleDataContext()
  const actividadesDOM = useRef()
  const clasesDOM = useRef()

  const estados = {

    E00: 'warning',
    E01: 'dangerous',
    E02: 'check'

  }

  const getActiveRows = (checksActive) => {

    const activeRows = [] 

    document.querySelectorAll('.check input:not(.header):checked').forEach(e => activeRows.push(e.parentElement.parentElement))

    return activeRows

  }

  const handleCheck = () => {

    const checks = document.querySelectorAll('.check input:not(.header)').length
    const checksActive = document.querySelectorAll('.check input:not(.header):checked').length

    if (checkAll.current) {

      if (checks === checksActive) checkAll.current.checked = true
      else checkAll.current.checked = false

      setView({...view, isToolsActive: checksActive })
      setRows(getActiveRows(checksActive))

    }
    
  }

  const handleCheckAll = (e) => {

    const checks = document.querySelectorAll('.check input:not(.header)')
    
    if (checkAll.current) {

      checks.forEach(check => check.checked = checkAll.current.checked)

      setView({...view, isToolsActive: checkAll.current.checked })
      setRows(getActiveRows(checkAll.current.checked))

    }

  }

  const handleSearch = async () => {

    fetch(`${process.env.REACT_APP_DB_URL}?query=${section.searchQuery}&pattern=${search.current.value}`, { mode: 'cors' })
      .then((res) => res.json()).then((content) => setData(content))
      .catch((error) => setData([]))

  }

  const handleFilter = (e) => {

    e.currentTarget.classList.toggle('active')

  }

  const handleChangeActividad = (e) => {

    const id = e.currentTarget.value

    if (id) {

      clasesDOM.current.disabled = false

      fetch(`${process.env.REACT_APP_DB_URL}?query=search_clase_by_actividad&actividad=${id}`, { mode: 'cors' })
        .then((res) => res.json()).then((content) => setClases(content))
        .catch(() => setClases([]))  
      
      clasesDOM.current.value = 'default'
        
    } else { clasesDOM.current.disabled = true }

  } 

  const handleFiltrarButton = () => {

    const idActividad= actividadesDOM.current.value
    const idClase= clasesDOM.current.value
    const filter = document.querySelector('.filter-option')
    const query = idClase==='default' ? `get_inscripciones_by_actividad&actividad=${idActividad}` : `get_inscripciones_by_actividad&actividad=${idActividad}&clase=${idClase}`

    if (idActividad==='default') {

      fetch(`${process.env.REACT_APP_DB_URL}?query=get_inscripciones`, { mode: 'cors' })
        .then((res) => res.json()).then((content) => setData(content))
        .catch((error) => setData([]))

    } else {

      console.log(query)

      fetch(`${process.env.REACT_APP_DB_URL}?query=${query}`, { mode: 'cors' })
        .then((res) => res.json()).then((content) => setData(content))
        .catch((error) => setData([]))

    }

    filter.classList.remove('active')

  }

  useEffect(() => {

    fetch(`${process.env.REACT_APP_DB_URL}?query=get_actividades`, { mode: 'cors' })
      .then((res) => res.json()).then((content) => setActividades(content))
      .catch(() => setActividades([]))

    fetch(`${process.env.REACT_APP_DB_URL}?query=get_clases`, { mode: 'cors' })
      .then((res) => res.json()).then((content) => setClases(content))
      .catch(() => setClases([]))

    return () => {

      handleCheck()

    }

  }, [])

  return (

    <div className="list">
      <div className="list-header">
        { section.id === 'inscriptions' && 
          <div className="filter">
            <div className="filter-option" title="Filtrar..." onClick={handleFilter}>
              <div className="icon">filter_list</div>
            </div> 
            <div className="filter-options">
              <select id="actividades" name="filtro-actividades" onChange={handleChangeActividad} ref={actividadesDOM}>
                <option value="default" defaultChecked>Todas las actividades</option>
                {

                  actividades.map((actividad, index) => {

                    return (

                      <option key={`actividad-${index}`} value={actividad.id}>{actividad.nombre}</option>

                    )

                  })

                }
              </select>
              <select id="clases" name="filtro-horarios"  ref={clasesDOM}>
                <option value="default" defaultChecked>Todas las clases</option>
                {

                  clases.map((actividad, index) => {

                    return (

                      <option key={`clase-${index}`} value={actividad.id}>{actividad.nombre}</option>

                    )

                  })

                }
              </select>
              <button onClick={handleFiltrarButton}>Filtrar</button>
            </div>
          </div>
          
        }
        
        <div className="searchbar">
          <div className="icon">search</div>
          <input type="text" placeholder="Buscar" ref={search} onChange={handleSearch}/>
        </div>
        <div className="counter">
          <span>{data && data.length}</span> resultados
        </div>
      </div>
      <div className="list-row header">
        <div className="list-column check" >
          <input type="checkbox" className="header" ref={checkAll} onChange={handleCheckAll}/>
          <div className="checkbox"></div>
          <div className="icon">check_small</div>
        </div>
        {

          section.listFields.map((field, index) => {

            return (

              <div key={`list-header-column-${section.id}-${index}`} className={`list-column text ${field.class}`}>
                {field.name}
              </div>

            )

          })

        }
      </div>
        <div className="list-content">
        {

          data.map((row, index) => {

            return (

              <div key={`list-header-column-${row.nombre}-${index}`} className={`list-row`} data-id={row.id}>
                <div className="list-column check">
                  <input type="checkbox" onChange={handleCheck}/>
                  <div className="checkbox"></div>
                  <div className="icon">check_small</div>
                </div>
                {

                  section.listFields.map((field, index) => {

                    return (

                      <div key={`list-header-column-${data.id}-${index}`} className={`list-column text ${field.class}`}>
                        {
                          field.id==='estado' 
                          ?

                          <div className={`icon ${estados[row[field.id]]}`} title={row.nombreEstado}>
                            {estados[row[field.id]]}
                          </div>

                          :
                          row[field.id]
                        
                        }
                      </div>

                    )

                  })

                }
              
              </div>

            )

          })

        }
      </div>
    </div>

  )

}

export default List

/**
 * 
 * 
 * <div className="list-column options" onClick={handleOptionsClick}>
                  <div className="icon">more_vert</div>
                  <div className="options-menu">
                  <div className="option-menu edit">
                      <div className="icon">edit</div>
                      <div className="text">Editar</div>
                    </div>
                    <div className="option-menu delete">
                      <div className="icon">delete</div>
                      <div className="text">Eliminar</div>
                    </div>
                  </div>
                </div>
 * 
 */