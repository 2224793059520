import React, { useState, useContext } from 'react'

const rowsContext = React.createContext()
const handleRowsContext = React.createContext()

export const useRowsContext = () => useContext(rowsContext)
export const useHandleRowsContext = () => useContext(handleRowsContext)

export const RowsProvider = ({children}) => {

  const [ rows, setRows ] = useState([])
  const handleRows = (data) => setRows(data)

  return (

    <rowsContext.Provider value={rows}>
      <handleRowsContext.Provider value={handleRows}>
        {children}
      </handleRowsContext.Provider>
    </rowsContext.Provider>

  )

}