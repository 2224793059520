import Aside from '../Aside/Aside'
import Header from '../Header/Header'
import Content from '../Content/Content'
import Tools from '../Tools/Tools'
import PopUp from '../PopUp/PopUp'
import bcrypt from 'bcryptjs'

import { ReactComponent as Logo } from '../../assets/logo.svg'
import { useAuthContext, useHandleAuthContext } from '../../providers/authProvider'

import './styles.scss'
import { useEffect, useRef } from 'react'

const Login = () => {

  const auth = useAuthContext()
  const setAuth = useHandleAuthContext()
  const usr = useRef()
  const pss = useRef()

  const handleLogin = () => {

    fetch(`${process.env.REACT_APP_DB_URL}?query=login&usr=${usr.current.value}&pss=${pss.current.value}`, { mode: 'cors' })
    .then((res) => res.json()).then((res) => {

        if (res[0].isValid==='1') {

          const salt = bcrypt.genSaltSync(10)
          const hash = bcrypt.hashSync(`${process.env.REACT_APP_SECRET}-${Date.now()}`, salt)

          fetch(`${process.env.REACT_APP_DB_URL}?query=setToken&token=${hash}`, { mode: 'cors' })
            .then((res) => res.json()).then((res) => {

              localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, hash)
              setAuth(true)

            })
            .catch((error) => console.log(error))

        } else {

          const err = document.getElementById('err')

          if (err) {
            
            err.classList.remove('disabled')
            setTimeout(() => err.classList.add('disabled'), 5000)

          }
          
        }

      }).catch((error) => console.log(error))

  }

  useEffect(() => {

    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)

    if (token) {

      fetch(`${process.env.REACT_APP_DB_URL}?query=validateToken&token=${token}`, { mode: 'cors' })
        .then((res) => res.json()).then((res) => setAuth(res[0].isValid==='1'))
        .catch((error) => console.log(error))

    }

  }, [])

  return (

    auth ?
      
      <>
        <PopUp/>
        <Header/>
        <Aside/>
        <Content/>
        <Tools/>
      </>

    : 

    <div className="login">
      <Logo/>
      <div className="title">
        GESTIÓN DE COOLFIT
      </div>
      <div className="input">
        <div className="icon">person</div>
        <input type="text" name="user" ref={usr} placeholder='Usuario' required/>
      </div>
      <div className="input">
        <div className="icon">password</div>
        <input type="password" name="password" ref={pss} placeholder='Contraseña' required/>
      </div>
      <div className="error disabled" id="err">Error al intentar iniciar sesión...</div>
      <div className="popup-button" onClick={handleLogin}>Iniciar sesión </div>
    </div>

  )

}

export default Login


/***
 * 
 * const handleLogin = () => {

    fetch(`${process.env.REACT_APP_DB_URL}?query=login&usr=${usr.current.value}&pss=${pss.current.value}`, { mode: 'cors' })
      .then((res) => res.json()).then((res) => {

        console.log(res)

        if (res[0].isValid==='1') {

          console.log('pasamos o no')

          const salt = bcrypt.genSaltSync(10)
          const hash = bcrypt.hashSync(`${process.env.REACT_APP_SECRET}-${Date.now()}`, salt)

          console.log('pasamos')

          fetch(`${process.env.REACT_APP_DB_URL}?query=setToken&token=${hash}`, { mode: 'cors' })
            .then((res) => res.json()).then((res) => {

              console.log('pasamos bien')

              localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, hash)
              setAuth(true)

            })
            .catch((error) => console.log(error))

        } else {

          const err = document.getElementById('err')

          if (err) {
            
            err.classList.remove('disabled')
            setTimeout(() => err.classList.add('disabled'), 5000)

          }
          
        }

      }).catch((error) => console.log(error))

  }
 * 
 * 
 */